<template>
    <div class="shop-info">
        <div
            class="location_item"
            @click="redirect({ path: 'branch', query: { id: shopData.id } })"
        >
            <h5>{{ shopData.shop }}</h5>
            <div class="detail d_flex">
                <p class="address">{{ shopData.address }}</p>
                <p class="open">{{ shopData.open }}</p>
                <p class="phone">{{ shopData.phone }}</p>
            </div>
        </div>
        <a class="call-btn" :href="`tel:+886${shopData.phone.replace('-','').replace('0','') }`">立即撥打</a>
    </div>
</template>

<script>
export default {
    name: 'LocationColumn',
    props: {
        shopData: Object,
    },
    methods: {
        redirect(link) {
            setTimeout(() => {
                this.$router.push(link);
            }, 200);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.shop-info {
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.location_item {
    // border: 2px solid $color_main_first;
    background-color: #F9F6F4;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 16px;
    // margin-bottom: 20px;
    text-align: left;
    display: block;
    transition: background 0.3s;
    h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        background-image: url('~@/assets/images/ic24ArrowR.svg');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 8px;
    }
    .detail {
        margin-top: 24px;
        p {
            margin-bottom: 10px;
            padding-left: 28px;
            background-repeat: no-repeat;
            background-position: left center;
            font-size: 16px;
            line-height: 19px;
        }
        .address {
            width: 100%;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        .open {
            width: 100%;
            margin-right: 10px;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        .phone {
            width: 100%;
            padding-top: 3px;
            padding-bottom: 3px;
        }

    }
    &:active {
        background: rgba(249, 98, 48, 0.05);
    }
}
.call-btn {
    background-color: $color_main_first;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    color:white;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: block;
}
</style>
